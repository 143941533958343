'use strict';

window.jQuery = window.$ = require('jquery');

// ======================================================
// Requirements
// ======================================================

const $            = require('jquery'),
      parsley      = require('parsleyjs'),
      alertify     = require('alertify.js'),
      _            = require('underscore'),
      fancybox     = require('fancybox')($),
      imagesLoaded = require('imagesloaded'),
      slick        = require('slick-carousel'),
      matchHeight  = require('jquery-match-height');

import TweenLite               from 'gsap';
let $body = $('body');

/*------------------------------------------------------------------
Navburger
 ------------------------------------------------------------------*/

function hamburger() {
    let $nav               = $('.header');
    let $hamburger         = $('#hamburger');
    let $lineOne           = $hamburger.find('span:first-child');
    let $lineTwo           = $hamburger.find('span:nth-child(2)');
    let $lineThree         = $hamburger.find('span:last-child');
    let hamburgerAnimation = new TimelineMax({ paused: true });
    let canClick           = true;

    hamburgerAnimation
        .to($lineOne, 0.2, { top: "50%" }, 'start')
        .to($lineThree, 0.2, { top: "50%" }, 'start')
        .set($lineTwo, { autoAlpha: 1 }, 'start')
        .set($lineTwo, { autoAlpha: 0 }, 'mid')
        .to($lineOne, 0.2, { rotation: 45 }, 'end')
        .to($lineThree, 0.2, { rotation: -45 }, 'end');

    $hamburger.on('click', function () {
        if (canClick) {
            canClick = false;
            if (!$hamburger.hasClass('open')) {
                hamburgerAnimation.restart();
                $hamburger.addClass('open');
                $nav.addClass('open');
                $body.addClass('menuVisible');
            } else {
                hamburgerAnimation.reverse();
                $hamburger.removeClass('open');
                $nav.removeClass('open');
                $body.removeClass('menuVisible');
            }
            setTimeout(function () {
                canClick = true;
            }, 500);
        }
    })
}

hamburger();

//==================================================================
// Match Height
//==================================================================

$('.heroItem').matchHeight();
$('.heroItem h5').matchHeight();

//==================================================================
// Images Loaded
//==================================================================

let $banner = $('.banner');

$banner.imagesLoaded({background: '.image'}, function () {
    $banner.addClass('loaded')
});

//==================================================================
// Navburger
//==================================================================

let $navburger = $('.navburger');

$navburger.on('click', function () {
    $('body').toggleClass('mobile-nav');
});

const $contactForm = $('#ContactForm_ContactForm');

//==================================================================
// Form Validation
//==================================================================

if ($contactForm.length > 0) {
    $contactForm.parsley({
        classHandler: (el) => {
            return el.$element.closest('.input-wrap');
        }
    }).on('form:submit', () => {
        $contactForm.addClass('busy');
        $.ajax({
            url : $contactForm.attr('action'),
            data: $contactForm.serialize()
        }).done(function (response) {
            let data = JSON.parse(response);
            alertify.alert(data.message);
            $contactForm[0].reset();
        }).fail(function (response) {
            let data = JSON.parse(response);
            alertify.alert(data.message);
        });
        return false;
    });
}

// ==================================================================
// Sticky Header
//==================================================================

let $header = $('header');

function init() {
    window.addEventListener('scroll', function () {
        let distanceY = window.pageYOffset || document.documentElement.scrollTop,
            shrinkOn  = 40;
        if (distanceY > shrinkOn) {
            $header.addClass('smaller');
        } else {
            if ($header.hasClass('smaller')) {
                $header.removeClass('smaller');
            }
        }
    });
}
window.onload = init();

$(document).ready(function () {
    $('a[href*="#"]').on('click', function (e) {

            if ($(this).parent().hasClass('externalLinks')) {
                return true;
            } else {

                e.preventDefault();

                let target  = this.hash;
                let $target = $(target);

                $('html, body').stop().animate({
                    'scrollTop': $target.offset().top - 80
                }, 900, 'swing', function () {
                    window.location.hash = target;
                });
            }
        }
    );
});

$('.js-testimonials').each(function(){
    $(this).slick({
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 7000,
    });
})